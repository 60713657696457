import React from "react";
import PageTransition from "gatsby-plugin-page-transitions";
import {
  Jumbotron,
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  Image
} from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import Section from "../components/Section/Section";
import SEO from "../components/Seo/Seo";
import { P, H1, H2, H5, Span } from "../components/Typography/Typography";
import percent from "../images/sf-20-percent.png";
import { Link } from "gatsby";

const CompaniesPage = () => (
  <PageTransition>
    <Layout>
      <SEO title="Companies" />
      <Container>
        <Jumbotron
          as="header"
          className="sf-companies d-flex align-items-center"
        >
          <Row>
            <Col lg={8}>
              <H1>Become our corporate partner</H1>
              <P>
                You can become our partner and bring your own speakers to
                showcase the technologies used in your company, the most
                important projects or plans for the future. We will promote the
                event and pass it on to all our internal channels reaching all
                the members of the community.
              </P>
            </Col>
          </Row>
        </Jumbotron>
      </Container>

      <Section className="sf-benefit">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col lg={7}>
              <H5 className="mb-5 text-center">
                Benefit from our know-how, support and online community to make
                your company better known locally and nationally.
              </H5>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <ListGroup horizontal="lg" className="my-3">
                <ListGroup.Item className="d-flex align-items-center rounded-0 sessions">
                  <P className="d-flex flex-column">
                    <Span>133</Span> talks sessions
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex align-items-center rounded-0 speakers">
                  <P className="d-flex flex-column">
                    <Span>251</Span> speakers
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex align-items-center rounded-0 workshops">
                  <P className="d-flex flex-column">
                    <Span>7</Span> workshops
                  </P>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex align-items-center rounded-0 hackathons">
                  <P className="d-flex flex-column">
                    <Span>2</Span> hackathons
                  </P>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="sf-profit-tax">
        <Container>
          <Row>
            <Col md={8} lg={7}>
              <H2>Direct 20% of the profit tax to Softbinator Foundation!</H2>
              <P>
                The sponsorship law gives you the right to choose what to do
                with some of the profit taxes that are collected by the state.
                Sponsorship is fully deductible if it falls within the limits of
                the law and if it is done by the end of the year, based on a
                sponsorship contract.
              </P>
              <Button
                className="partner"
                variant="primary"
                as={Link}
                to="/contact"
              >
                Let's partner
              </Button>
            </Col>
            <Col md={4} lg={5}>
              <Image src={percent} className="img-fluid" alt="" />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="sf-how-it-works">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={6} className="d-flex flex-column align-items-center">
              <H2>Not sure how it works?</H2>
              <H5 className="text-center">
                We are at your disposal with additional information on the
                procedure for directing 20% ​​of the profit
              </H5>
              <Button
                size="large"
                as={Link}
                to="/contact"
                variant="outline-secondary"
                className="mt-3"
              >
                Contact us
              </Button>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  </PageTransition>
);

export default CompaniesPage;
